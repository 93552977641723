<template>
  <div class="addBox">
    <!-- 页面顶部功能区 -->
    <div class="top">
      <div class="gary-l" @click="returnWeb">返回店铺</div>
      <p v-show="showBtn" class="title">
        <span>当前页面：</span>
        {{ pageTitle }}
      </p>
      <div class="topRight">
        <div class="text-btn" v-show="showBtn" @click="applyTem">申请为模板</div>
        <el-popover placement="bottom" width="224" trigger="click">
          <img style="width: 200px; height: 200px" v-if="qrcode" :src="qrcode" alt="" />
          <div class="text-btn" style="margin-right: 24px" slot="reference" v-show="showBtn" @click="goTY">立刻体验</div>
        </el-popover>
        <div v-show="showBtn" class="gary-s" @click="storage">保存</div>
        <div v-show="showBtn" class="blue-s" @click="release">发布</div>
      </div>
    </div>
    <!-- 页面下方 菜单 装修区 -->
    <div class="bot">
      <!-- 左侧菜单区 -->
      <div class="leftMenu">
        <el-menu :default-active="active" :router="true">
          <el-menu-item v-for="(item, index) in routes" :key="index" :index="item.path">{{ item.meta.name }}
          </el-menu-item>
        </el-menu>
      </div>
      <div class="rightContant">
        <keep-alive>
          <router-view @needStorage="needStorage" ref="shopDiy" v-if="this.$route.meta.name == '店铺装修组件'"
            :pageTitle="pageTitle" @getIndexSet="getSetInfo"></router-view>
        </keep-alive>
        <router-view :sonStyleArr="sonStyleArr" v-if="this.$route.meta.name != '店铺装修组件'"></router-view>
        <div class="t_logo" v-show="!is_form">
          <el-image :src="require('@/assets/t_logo.png')"></el-image>
        </div>
      </div>
    </div>
    <el-dialog title="申请为模版" :visible.sync="tobeTemFlag" width="40%" @closed="handleClose">
      <div class="temBox">
        <div class="temImg">
          <div style="line-height: 40px">模版封面</div>
          <ReadyUploadSource :showStyle="{
            width: '200px',
            height: '356px',
          }" :path="temConfig.images" @getSource="getImg" @removeThis="removeImg"></ReadyUploadSource>
          <p v-if="!temConfig.images" style="color: red; margin: 5px">请上传模版封面</p>
        </div>
        <el-form :model="temConfig" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="模版名称" prop="name">
            <el-input v-model="temConfig.name"></el-input>
          </el-form-item>
          <el-form-item label="模版分类" prop="industry_id">
            <el-select v-model="temConfig.industry_id" placeholder="请选择">
              <el-option v-for="(item, index) in styleArr" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="模版详情" prop="details">
            <el-input v-model="temConfig.details"></el-input>
          </el-form-item>
          <el-form-item label="模版价格" prop="money">
            <el-input type="Number" :min="0" v-model="temConfig.money"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="tobeTemFlag = false">取 消</el-button>
        <el-button type="primary" @click="uploadTem">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource.vue';
export default {
  components: {
    ReadyUploadSource,
  },
  data () {
    return {
      pageTitle: '页面标题',
      active: '/addPages/customTemplate',
      showBtn: false,
      updateId: null,
      isStorage: 0,
      // 最后操作项 0-草稿箱 1-发布
      lastOperation: 1,
      qrcode: null,
      tobeTemFlag: false,
      styleArr: [],
      sonStyleArr: [],
      rules: {
        industry_id: [{ required: true, message: '请选择模版分类', trigger: 'change' }],
        name: [{ required: true, message: '请填写模版名称', trigger: 'blur' }],
        details: [{ required: true, message: '请填写模版详情', trigger: 'blur' }],
        money: [{ required: true, message: '请填写模版价格', trigger: 'blur' }],
      },
      temConfig: {
        name: '',
        images: '',
        industry_id: '',
        details: '',
        money: '',
      },
      is_form: '',
    };
  },
  created () {
    this.getStyleArr();
    //window.addEventListener('popstate', this.goBack, true);
  },
  methods: {
    // 获取模版分类列表
    getStyleArr () {
      let that = this;
      this.$axios.post(this.$api.shopDiy.templatelist).then(res => {
        if (res.code == 0) {
          that.styleArr = res.result;
          that.sonStyleArr = JSON.parse(JSON.stringify(res.result));
          that.sonStyleArr.unshift({ id: 0, name: '全部模版' });
        }
      });
    },
    goTY () {
      let f_id = sessionStorage.getItem('f_id');
      if (!f_id) {
        this.$message.warning('请先发布页面');
        return;
      }
      this.$axios
        .post(this.$api.shopDiy.getQrcode, {
          id: f_id,
        })
        .then(res => {
          if (res.code == 0) {
            this.qrcode = res.result;
          }
        });
    },
    back () {
      this.$confirm('店铺装修组件有改动尚未保存，跳转页面将会丢失数据！！！', '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '继续跳转',
        cancelButtonText: '前往保存',
      })
        .then(() => {
          this.$router.push({
            name: `shopDecoration`,
            params: {
              type: this.lastOperation,
            },
          });
        })
        .catch(() => {
          // this.$router.push('/addPages/decorationComponents')
        });
    },
    // 返回后台
    returnWeb () {
      if (sessionStorage.getItem('#_cNum') && Number(sessionStorage.getItem('#_cNum')) != 0) {
        this.$confirm('店铺装修组件有改动尚未保存，跳转页面将会丢失数据！！！', '提示', {
          confirmButtonText: '继续跳转',
          cancelButtonText: '前往保存',
          type: 'warning',
        })
          .then(res => {
            this.$router.push({
              name: `shopManagement-shopDecoration`,
              params: {
                type: this.lastOperation,
              },
            });
          })
          .catch(err => {
            if (this.$route.path != '/addPages/decorationComponents') {
              this.$router.push({
                path: '/addPages/decorationComponents',
              });
            }
          });
      } else {
        this.$router.push({
          name: `shopManagement-shopDecoration`,
          params: {
            type: this.lastOperation,
          },
        });
      }
    },
    getSetInfo (val) {
      this.pageTitle = val;
    },
    // 点击保存配置
    storage () {
      if (this.$refs.shopDiy.basicSet.showPlatform.length == 0) {
        this.$message({
          message: '基础设置中展示平台不可为空!',
          type: 'error',
        });
        return;
      }
      if (!this.pageTitle) {
        this.$message.warning('页面标题不能为空');
        return;
      }
      if (!this.$refs.shopDiy.basicSet.pageName) {
        this.$message.warning('页面名称不能为空');
        return;
      }

      this.$refs.shopDiy.lastBaisc = JSON.stringify(this.$refs.shopDiy.basicSet);
      this.$refs.shopDiy.basicSet.showPlatform = this.$refs.shopDiy.basicSet.showPlatform.sort((a, b) => a - b);
      this.$set(this.$refs.shopDiy.basicSet, 'pageTitle', this.pageTitle);
      let pageConfig = {
        basicSet: this.$refs.shopDiy.basicSet,
        choose_s_color: this.$refs.shopDiy.choose_s_color,
        addComponens: this.$refs.shopDiy.addComponens,
      };
      let that = this;
      let obj = {};
      if (sessionStorage.getItem('c_id')) {
        // 草稿箱页面更新 ->带该页面的草稿箱id
        obj = {
          id: sessionStorage.getItem('c_id'),
          page_name: that.$refs.shopDiy.basicSet.pageName,
          page_type: that.$refs.shopDiy.basicSet.pageClass,
          show_type: that.$refs.shopDiy.basicSet.showPlatform.join(','),
          page_value: JSON.stringify(pageConfig),
        };
      } else {
        // 新增页面到草稿箱
        obj = {
          page_name: that.$refs.shopDiy.basicSet.pageName,
          page_type: that.$refs.shopDiy.basicSet.pageClass,
          show_type: that.$refs.shopDiy.basicSet.showPlatform.join(','),
          page_value: JSON.stringify(pageConfig),
        };
      }
      this.$axios.post(this.$api.shopDiy.Drafts, obj).then(res => {
        if (res.code == 0) {
          if (sessionStorage.getItem('c_id')) {
            this.$message({
              message: '修改成功',
              type: 'success',
            });
          } else {
            this.$message({
              message: '已保存到草稿箱',
              type: 'success',
            });
            sessionStorage.setItem('c_id', res.result);
          }
          that.lastOperation = 0;
          that.isStorage = 1;
          that.$refs.shopDiy.count = 0;
          sessionStorage.setItem('#_update', 1);
          sessionStorage.setItem(
            'val',
            JSON.stringify({
              data: pageConfig,
            })
          );
          sessionStorage.setItem('#_cNum', that.$refs.shopDiy.count);
          return;
        }
        this.$message({
          message: `${res.msg}`,
          type: 'error',
        });
      });
    },
    // 点击发布
    release () {
      if (this.isStorage === 0) {
        this.$message.warning('请先保存此次修改');
        return;
      }
      if (this.$refs.shopDiy.basicSet.showPlatform.length == 0) {
        this.$message({
          message: '基础设置中展示平台不可为空!',
          type: 'error',
        });
        return;
      }
      if (!this.pageTitle) {
        this.$message.warning('页面标题不能为空');
        return;
      }
      if (!this.$refs.shopDiy.basicSet.pageName) {
        this.$message.warning('页面名称不能为空');
        return;
      }
      this.$refs.shopDiy.basicSet.showPlatform = this.$refs.shopDiy.basicSet.showPlatform.sort((a, b) => a - b);
      this.$set(this.$refs.shopDiy.basicSet, 'pageTitle', this.pageTitle);
      let pageConfig = {
        basicSet: this.$refs.shopDiy.basicSet,
        choose_s_color: this.$refs.shopDiy.choose_s_color,
        addComponens: this.$refs.shopDiy.addComponens,
      };
      let that = this;
      let pageStyle = JSON.parse(sessionStorage.getItem('val')).data.basicSet.pageClass; // 2-首页 1-自定义页面
      let obj = {};
      let str = '';
      if (sessionStorage.getItem('f_id')) {
        obj = {
          id: sessionStorage.getItem('f_id'),
          page_name: that.$refs.shopDiy.basicSet.pageName,
          page_type: that.$refs.shopDiy.basicSet.pageClass,
          show_type: that.$refs.shopDiy.basicSet.showPlatform.join(','),
          page_value: JSON.stringify(pageConfig),
        };
        str = pageStyle == 1 ? '自定义页面' : '首页' + '修改成功';
      } else {
        obj = {
          drafts_id: sessionStorage.getItem('c_id'),
          page_name: that.$refs.shopDiy.basicSet.pageName,
          page_type: that.$refs.shopDiy.basicSet.pageClass,
          show_type: that.$refs.shopDiy.basicSet.showPlatform.join(','),
          page_value: JSON.stringify(pageConfig),
        };
        str = pageStyle == 1 ? '自定义页面' : '首页' + '发布成功';
      }
      this.$axios.post(this.$api.shopDiy.createOrUpdatePage, obj).then(res => {
        if (res.code == 0) {
          this.$message({
            message: `${str}`,
            type: 'success',
          });
          that.lastOperation = 1;
          that.$refs.shopDiy.count = 0;
          sessionStorage.setItem('f_id', res.result);
          sessionStorage.setItem(
            'val',
            JSON.stringify({
              data: pageConfig,
            })
          );
          sessionStorage.setItem('#_cNum', that.$refs.shopDiy.count);
          return;
        } else if (res.code == -1) {
          that.$message.error(res.msg ? res.msg : '服务器错误');
          return;
        }
        this.$message({
          message: `${res.msg}`,
          type: 'error',
        });
      });
    },
    // 需要点击保存
    needStorage () {
      if (sessionStorage.getItem('#_cNum')) {
        this.isStorage = 0;
      }
    },
    applyTem () {
      if (this.$refs.shopDiy.basicSet.pageClass !== 2) {
        this.$message.warning('只有首页才能保存为模版');
        return;
      }
      if (this.isStorage === 0) {
        this.$message.warning('请先保存此次修改');
        return;
      }
      this.tobeTemFlag = true;
    },
    // 获取图片
    getImg (imgUrl) {
      this.temConfig.images = imgUrl.path;
    },
    removeImg () {
      this.temConfig.images = '';
    },
    // 上传模版
    uploadTem () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (!this.temConfig.images) {
            this.$message.warning('请上传模版封面');
            return;
          }
          this.temConfig.drafts_id = sessionStorage.getItem('c_id');
          this.$axios.post(this.$api.shopDiy.releaseTemplate, this.temConfig).then(res => {
            if (res.code === 0) {
              this.$message.success(`申请成功，请等待审核`);
              this.tobeTemFlag = false;
            } else {
              this.$message.error(`${res.msg}`);
            }
          });
        }
      });
    },
    handleClose () {
      this.temConfig = {
        name: '',
        images: '',
        industry_id: '',
        details: '',
        money: '',
      };
    },
  },
  computed: {
    routes () {
      let arr = this.$router.options.routes;
      return arr.find(i => i.path == '/addPages').children;
    },
  },
  destroyed () {
    window.removeEventListener('popstate', this.goBack, false);
  },
  beforeDestroy () {
    sessionStorage.clear('val');
    sessionStorage.clear('#_cNum');
    sessionStorage.clear('#_update');
  },
  watch: {
    $route: {
      handler (val, old) {
        this.is_form = val.meta.form;
        this.active = this.routes.find(i => i.path == val.path).path;
        if (val.path == '/addPages/decorationComponents') {
          this.showBtn = true;
          if (this.$route.query.page_type) {
            this.$nextTick(() => {
              this.$refs.shopDiy.basicSet = {
                pageTitle: '',
                // 2-首页 1-自定义页面
                pageClass: 1,
                // 展示平台
                showPlatform: [2, 3, 4, 5, 6, 7],
                titleBgColor: '#fff',
                // 0-黑色 1-白色
                titleColor: 0,
                wholeBgColor: '#f5f5f5',
              };
              this.$refs.shopDiy.addComponens = [];
              this.$refs.shopDiy.chooseTop();
            });
          }
          if (sessionStorage.getItem('val') && sessionStorage.getItem('#_update')) {
            this.$nextTick(() => {
              let data = JSON.parse(sessionStorage.getItem('val')).data;
              this.pageTitle = data.basicSet.pageTitle;
              this.$set(this.$refs.shopDiy, 'addComponens', data.addComponens);
              this.$refs.shopDiy.checkMaxId();
              data.basicSet.pageClass = Number(data.basicSet.pageClass);
              data.basicSet.titleColor = Number(data.basicSet.titleColor);
              this.$set(this.$refs.shopDiy, 'basicSet', data.basicSet);
              if (data.choose_s_color) this.$set(this.$refs.shopDiy, 'choose_s_color', data.choose_s_color);
              data.basicSet.showPlatform.map((item, index) => {
                data.basicSet.showPlatform.splice(index, 1, Number(item));
              });
              this.pageName = data.basicSet.pageTitle;
              this.$refs.shopDiy.count = 0;
              sessionStorage.setItem('#_cNum', this.$refs.shopDiy.count);
            });
          }
        } else {
          this.showBtn = false;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="less" scoped>
@import url(./publicStyle.less);

.addBox {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    position: relative;
    border-bottom: 1px solid #e3e4e6;
    padding: 15px 20px;

    .title {
      font-size: 14px;
      flex-shrink: 0;
      white-space: nowrap;

      span {
        color: #aeaeae;
      }
    }

    .topRight {
      display: flex;
      flex-wrap: nowrap;

      &>div:not(:last-child) {
        margin-right: 24px;
      }
    }

    .experience {
      margin-left: 10px;
      position: relative;

      img {
        width: 160px;
        height: 160px;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 100;
      }
    }
  }

  .bot {
    width: 100%;
    min-height: calc(100% - 70px);
    display: flex;

    .leftMenu {
      width: 150px;
      min-height: 100%;
      overflow: hidden;
      overflow-y: auto;
      background-color: #fff;

      .el-menu {
        height: 100%;

        .is-active {
          background-color: #f4f9fe !important;
        }
      }
    }

    .rightContant {
      width: calc(100% - 150px);
      min-height: 100%;
      background-color: #fff;

      // overflow-y: auto;
      .t_logo {
        text-align: center;
        margin: 20px 0;
      }
    }
  }
}

.temBox {
  display: flex;
}
</style>
